<template>
  <div class="common-upload-container flex justify-center items-center" :class="{ 'min-client-height': minClient }">
    <div class="upload-position-container">
      <div class="upload-box upload-single flex justify-center items-center" @mouseenter="handleShowImageList"
        @mouseleave="handleHiddenImageList" v-authenticate:PORTRAIT_BACKGROUND_REMOVER>
        <commonUpload :fileList="fileList" :setFileList="setFileList" :reUpload="reUpload" :multiple="false"
          :customRequest="customRequest" :onBeforeUpload="onBeforeUpload"
          accept=".JPG,.jpg,.png,.PNG,.webp,.WEBP,.jpeg,.JPEG" :limitNum="10" :iconUrl="iconUploadSingle"
          creditTip="&nbsp" placeHolder="Upload a portrait image" :iconStyle="{
              width: '46px',
              height: '58px',
              'margin-bottom': '25px',
            }" />
      </div>
      <div class="image-box-background" @mouseenter="handleShowImageList" @mouseleave="handleHiddenImageList"
        v-if="isShowImageList">
        <div class="inner-image-box">
          <div class="box-arrow"></div>
          <p class="tooltip-title">No image? Try one of these</p>
          <div class="example-images-box">
            <div class="example-image-icon-box" v-for="item in imagesList" :key="item.id"
              v-authenticate:PORTRAIT_BACKGROUND_REMOVER="{ ok: () => handleSelectImage(item) }">
              <div class="mask-item"></div>
              <img :src="item.iconSrc" class="example-image-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="upload-box upload-multiple flex justify-center items-center" v-authenticate:BOOLTOOL_BATCH_PROCESS>
      <commonUpload :fileList="fileList" :multiple="true" :setFileList="setFileList" :reUpload="reUpload"
        accept=".JPG,.jpg,.png,.PNG,.webp,.WEBP,.jpeg,.JPEG" :customRequest="customBatchRequest" :limitNum="20"
        :iconUrl="iconUploadMultiple" :iconStyle="{
            width: '57px',
            height: '69px',
            'margin-bottom': '25px',
          }" placeHolder="Batch upload (Max 20 images)"
        :creditTip="batchProcessResource.unlimitedNum ? '&nbsp;' : `Balance: ${batchProcessResource.num || 0}`" />
    </div>
  </div>
</template>
<script setup>
import { ref, defineEmits, onUnmounted, onMounted } from "vue";
import commonUpload from "@/components/custom-upload/index.vue";
import iconUploadSingle from "@/assets/upload-images/icon_upload_single.svg";
import iconUploadMultiple from "@/assets/upload-images/icon_upload_mutiple_pro.svg";
import removeIcon1 from "@/assets/example-images/remove_icon_1.webp";
import removeIcon2 from "@/assets/example-images/remove_icon_2.webp";
import removeIcon3 from "@/assets/example-images/remove_icon_3.webp";
import removeSrc1 from "@/assets/example-images/remove_src_1.webp";
import removeSrc2 from "@/assets/example-images/remove_src_2.webp";
import removeSrc3 from "@/assets/example-images/remove_src_3.webp";
import loadImage from "blueimp-load-image";
import dataUtils from "@/utils/util.js";
import { authenticator } from "@/utils";
import { storeToRefs } from 'pinia'
import {
  MessageLoading,
  destroy,
} from "@/components/boolv-ui/Message/index.js";
import { useUserStore, useCheckResourceStore, useSideStore, useViewStore, useSubscriptionInfo } from '@/store/index.js'
const user = useUserStore();
const { deductResourceFn } = useCheckResourceStore()
const sideStore = useSideStore()
const subscriptionInfo = useSubscriptionInfo()
const batchProcessResource = subscriptionInfo.getUserResource('BOOLTOOL_BATCH_PROCESS')
const viewStore = useViewStore()
const { minClient } = storeToRefs(sideStore)
const uploadFileList = ref([]);
const uploadCount = ref(0);
const formateImageList = ref([]);
const isShowImageList = ref(false);
const currentSelectItem = ref(null);
const isBatch = ref(false);
const reUpload = ref(false);
const imagesList = ref([
  {
    id: 1,
    iconSrc: removeIcon1,
    src: removeSrc1,
  },
  {
    id: 2,
    iconSrc: removeIcon2,
    src: removeSrc2,
  },
  {
    id: 3,
    iconSrc: removeIcon3,
    src: removeSrc3,
  },
]);
const preload = () => {
  imagesList.value.forEach(item => {
    const img = new Image();
    img.src = item.iconSrc;
  })
}
onMounted(() => {
  // viewStore.setMinViewWidth('1400px');
  preload();
})
const emits = defineEmits(["uploadComplete"]);

const setIsBatch = (value) => {
  isBatch.value = value;
  emits("isBatch", value);
  reUpload.value = true
}

const customRequest = () => {
  setIsBatch(false)
};
// 单图上传
const customBatchRequest = () => {
  setIsBatch(true)
};

// 选择单张示例图片
const handleSelectImage = (item) => {
  setIsBatch(false)
  sessionStorage.setItem("sourceImageId", JSON.stringify([`default_image_${item.id}`]));
  currentSelectItem.value = item;
  uploadFileList.value = [item.src];
  formateImageList.value = formateImageToImageData([item.src]);
};

// 本地上传完成，设置图片数组，后续上传
const setFileList = (fileList = []) => {
  destroy()
  MessageLoading("Uploading, it will take a while");
  const fileArray = fileList.map((file) => file.originFileObj);
  uploadFileList.value = fileArray;
  formateImageList.value = formateImageToImageData(fileArray);
};

// 将图片转化为imageData等数据格式,，这个步骤可以放到后面一步操作
const formateImageToImageData = (fileList) => {
  const uploadImageData = [];
  uploadCount.value = 0;
  fileList.forEach((file, index) => {
    loadImage(
      file,
      async (img) => {

        if (img.type === "error") {
          return
        }

        const tempCtx = dataUtils.createCanvasCtx(img);
        tempCtx.drawImage(img, 0, 0);
        const imageData = tempCtx.getImageData(0, 0, img.width, img.height);
        uploadImageData.push({
          id: index + 1,
          img,
          file: file,
          operationalData: tempCtx.getImageData(0, 0, img.width, img.height),
          originImageData: imageData, //不可更改
        });

        uploadCount.value += 1;

        // 监听上传进度
        if (uploadCount.value === uploadFileList.value.length) {
          try {
            // await checkCurrentResource('REMOVER_BACKGROUND', isBatch.value)
            await deductResourceFn('REMOVER_BACKGROUND', isBatch.value, uploadFileList.value.length);
            await authenticator.refresh();
            // 上传完成
            destroy()
            emits("uploadComplete", formateImageList.value);
          } catch (err) {
            destroy()
            reUpload.value = true
          }
        }
      },
      {
        cover: true,
        crop: true,
        canvas: true,
        crossOrigin: "Anonymous",
      }
    );
  });
  return uploadImageData;
};

// 示例图片的显隐
const handleShowImageList = () => {
  isShowImageList.value = true;
};

const handleHiddenImageList = () => {
  isShowImageList.value = false;
};

onUnmounted(() => {
  destroy()
})
</script>

<style lang="less" scoped>
.common-upload-container {
  min-height: calc(100vh - 60px);
  padding: 60px 0;
  min-width: 1400px;

  .tooltip-card {
    width: 394px;

    left: "280px";
  }

  :global(.ant-tooltip-content) {
    width: 394px;
    height: 100%;
  }

  :global(.ant-tooltip-inner) {
    width: 394px;
    height: 100%;
    color: #8f959e;
  }
}

.upload-box {
  width: 277px;
  height: 196px;
  padding-top: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #f8f5ff;
  }
}

.upload-single {
  margin-right: 34px;
}

.example-images-container {
  width: 394px;

  background-color: green;
}

.mask-item {
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 4px;
  opacity: 0;
  background-color: #000;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    opacity: 0.3;
  }
}

.example-image-icon {
  width: 110px;
  height: 110px;
  border-radius: 4px;
}


.example-images-box {
  display: flex;
  justify-content: space-between;
  padding: 22px 20px;
}

.image-box-background {
  width: 394px;
  height: 230px;
  position: absolute;
  padding-top: 30px;
  left: -60px;
}

.inner-image-box {
  width: 394px;
  height: 212px;
  position: absolute;
  // background-color: green;
  // margin-top: 14px;

  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.upload-position-container {
  position: relative;
}

.example-image-icon-box {
  position: relative;
  cursor: pointer;
  background-color: #F6F6F6;
  width: 110px;
  height: 110px;
  border-radius: 4px
}


.inner-image-box:before {
  width: 0px;
  height: 0px;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: -32px;
  padding: 0;
  border: 16px solid transparent;
  border-color: transparent transparent #ffffff transparent;
  content: '';
  z-index: 12;
}

.inner-image-box:after {
  width: 0px;
  height: 0px;
  position: absolute;
  left: 50%;
  margin-left: -9.5px;
  top: -35px;
  padding: 0;
  border: 17.5px solid transparent;
  border-color: transparent transparent #f1f1f1 transparent;
  content: '';
  z-index: 10
}
</style>
