<template>
  <div class="back-ground-remover-wrap">
    <!-- 顶部操作区域 start-->
    <div class="top-container">
      <div class="back-btn-container">
        <back-btn @back="handleBack" />
      </div>
      <div class="btn-container" v-if="innerImageList && innerImageList.length">
        <div class="flex select-box" @click="handleSelectAll" v-if="isBatchAddBackground || isBatchDownload">
          <img :src="isSelectImageAll ? iconSelected : iconNoSelect" :style="{ width: '16px', height: '16px' }" /> <span
            :style="{ 'margin-left': '6px' }">Select
            all</span>
        </div>
        <div v-else class="flex">
          <custom-btn activeColor="rgba(135, 94, 255, 0.08)" :disabled="isHandledNum < innerImageList.length"
            :disabledStyle="{ background: '#fff', color: '#d5c6ff', border: '1px solid #d5c6ff', }"
            :customStyle="{ width: '180px', height: '42px', background: '#fff', border: '1px solid #875EFF', 'border-radius': '100px', color: '#875EFF', 'margin-right': '24px' }"
            @click="handleSelectBatchAddBackground">Batch add background
          </custom-btn>
          <custom-btn activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)"
            :disabledStyle="{ background: '#d5c6ff', color: '#fff' }" :disabled="isHandledNum < innerImageList.length"
            :customStyle="{ width: '180px', height: '42px', background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)', 'border-radius': '100px', color: '#FFFFFF' }"
            @click="handleSelectBatchDownload">Batch download
          </custom-btn>
        </div>
      </div>
    </div>
    <!-- 顶部操作区域 end-->

    <!-- 图片展示区域 start-->
    <div class="image-show-container">
      <!-- 多图 -->
      <div class="flex" v-if="innerImageList && innerImageList.length">
        <div class="backGroundRemovers-container" :style="gridStyle">
          <div class="position-box" @mouseenter="(e) => handleMattingItemBoxMouseEnter(e, item, index)"
            @mouseleave="(e) => handleMattingItemBoxMouseLeave(e, item, index)" v-for="(item, index) in innerImageList"
            :key="index">
            <div>
              <div class="result-item-box">
                <div class="image-box result-image-box" style="width: 326px; height: 326px;">
                  <image-board ref="resultBoardRef" :srcData="item.removeBgResult" :isModelDone="isModelDone"
                    :bgData="item.bgImageData" v-if="item.removeBgResult" :imageRect="item.imageRect" />
                  <image-board :srcData="item.originImageData" :isModelDone="isModelDone" v-else />
                </div>

                <div class="buttons-container buttons-container-center"
                  v-if="currentEditItem?.id === item.id && item.showBtns === true">
                  <custom-btn
                    :customStyle="{ width: '162px', height: '36px', background: '#FFFFFF', 'border-radius': '100px', color: '#060606', 'margin-bottom': '18px' }"
                    @click="(e) => handleItemEditRemove(e, item)" activeColor='#EDEDED'>Edit
                    removal</custom-btn>
                  <custom-btn
                    :customStyle="{ width: '162px', height: '36px', background: '#FFFFFF', 'border-radius': '100px', color: '#060606' }"
                    @click="(e) => handleItemChangeBg(e, item)" activeColor='#EDEDED'> {{
                      item.isEdit ? 'Replace backgound' :
                      'Add background'
                    }}</custom-btn>
                </div>
              </div>
              <div class="loading-mask-box" v-if="!item.removeBgResult"></div>
              <div class="mask-box" v-if="isBatchAddBackground || isBatchDownload"
                :style="{ background: selectedItemIndexList.includes(item.id) ? 'rgba(0, 0, 0, 0.15)' : none }"
                @click="handleSelectItem(item)">

                <!-- <a-checkbox v-model:checked="() => {selectedItemIndexList.includes(item.id) ? true : false}" class="icon-checkbox">

                        </a-checkbox> -->
                <img :src="selectedItemIndexList.includes(item.id) ? iconChecked : iconNoCheck" class="icon-checkbox" />
              </div>

            </div>
          </div>
        </div>
      </div>

      <loading-mask v-else />
    </div>
    <!-- 图片展示区域 end-->

    <!-- 擦除编辑弹窗 -->
    <matting-edit-modal :isShowMattingModal="isShowMattingModal" :rawImage="rawImage" :resultImage="resultImage"
      @closeModal="handleMattingModalClose" @savaMattingImage="handleSavaMattingImage" :isBatch="true" />

    <!-- 替换背景弹窗 -->
    <bg-replace-modal :isShowReplaceBgModal="isShowReplaceBgModal" :resultImageData="currentEditData.srcImageData"
      :currentBgItem="currentEditData.bgItem" :resultImageRect="currentEditData.srcImageRect"
      @close="handleCloseReplaceBgModal" @confirm="handleConfirmReplaceBg" :uploadUri="inputS3Uri" :isBatch="true"
      :isEdit="currentEditData.isEdit" />

    <!-- 批量替换背景弹窗 -->
    <batch-bgReplace-modal :isShowReplaceBgModal="isShowBitchReplaceBgModal" @close="handleCloseBitchReplaceBgModal"
      :resultImageData="resultImage" @confirmReplaceBg="handleConfirmBitchReplaceBg" :repeatNum="5"
      :uploadUri="inputS3Uri" imageWidth="130px" :isEdit="isEdit" />

    <!-- 替换背景弹窗 -->
    <!-- <bg-replace-modal :isShowReplaceBgModal="isShowReplaceBgModal" :resultImageData="currentEditData.srcImageData"
              :resultImageRect="currentEditData.srcImageRect" :currentBgItem="currentEditData.bgItem"
              @close="handleCloseReplaceBgModal" @confirm="handleBgReplaceConfirm" /> -->

    <!-- 悬浮操作按钮 -->
    <div class="btn-position" v-if="isBatchDownload">
      <div class="flex justify-center">
        <custom-btn activeColor="linear-gradient(289deg, #EDEDED 18.5%, #EDEDED 99.95%)"
          :customStyle="{ width: '138px', height: '42px', background: '#fff', 'border-radius': '100px', color: '#060606', 'margin-right': '48px', 'box-shadow': '0px 6px 18px rgba(0, 0, 0, 0.1)' }"
          @click="handleCancel">Cancel
        </custom-btn>
        <custom-btn activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)"
          :customStyle="{ width: '138px', height: '36px', background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)', 'border-radius': '100px', color: '#FFFFFF', 'box-shadow': '0px 6px 18px rgba(138, 98, 255, 0.43)' }"
          @click="handleBatchDownload">Download ( {{ selectedItemIndexList.length || 0 }} )
        </custom-btn>
      </div>
    </div>
    <div class="btn-position" v-if="isBatchAddBackground">
      <div class="flex justify-center">
        <custom-btn activeColor="linear-gradient(289deg, #EDEDED 18.5%, #EDEDED 99.95%)"
          :customStyle="{ width: '172px', height: '42px', background: '#fff', 'border-radius': '100px', color: '#060606', 'margin-right': '48px', 'box-shadow': '0px 6px 18px rgba(0, 0, 0, 0.1)' }"
          @click="handleCancel">Cancel
        </custom-btn>
        <custom-btn activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)"
          :customStyle="{ width: '172px', height: '42px', background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)', 'border-radius': '100px', color: '#FFFFFF', 'box-shadow': '0px 6px 18px rgba(138, 98, 255, 0.43)' }"
          @click="handleBatchAddBackground">Add background ( {{ selectedItemIndexList.length || 0 }} )
        </custom-btn>
      </div>
    </div>

  </div>
</template>

<script setup>
import loadImage from "blueimp-load-image";
import * as md5 from "md5";
import BackBtn from '@/components/boolv-ui/back-btn'
import { runModelUtils } from "@/utils";
import { onMounted, ref, watch, defineProps, watchEffect, reactive, defineEmits, computed, onUnmounted } from "vue";
import MattingEditModal from "@/components/matting-edit-modal";
import BgReplaceModal from "@/components/bg-replace-modal/index.vue";
import BatchBgReplaceModal from "@/components/bg-replace-modal/batch-replace.vue";
import dataUtils from "@/utils/util.js";
import ImageBoard from '@/components/image-board';
import dncData from '@/utils/handleDncData.js'
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";
import iconChecked from '@/assets/common-icons/icon_checked.svg'
import iconNoCheck from '@/assets/common-icons/icon_checkbox.svg'
import iconNoSelect from '@/assets/common-icons/icon_no_select.svg'
import iconSelected from '@/assets/common-icons/icon_selected.svg'
import { uploadImageSingle } from '@/api/upload/upload.js'
import { eventTracking, postCanvasToUrl } from "@/utils/eventTracking.js";
import LoadingMask from '@/components/boolv-ui/loading-mask/index.vue'
import { saveImgZip, transformBase64 } from '@/utils/downloadZip.js'
import {
  MessageLoading,
  destroy,
  MessageSuccess
} from "@/components/boolv-ui/Message/index.js";
import { useSideStore } from "@/store";
const sideStore = useSideStore();
const emits = defineEmits(['back']);

const props = defineProps({
  modelFilepath: String,
  imageSize: Object,
  imageUrls: Array,
  warmupModel: Function,
  preProcess: Function,
  postProcess: Function,
  resizeSize: Object,
  imageList: Array,
  uploadSuccessImages: String,
});

// 图片上传列表
const innerImageList = ref([])

// 模型加载
const modelFile = ref(new ArrayBuffer(0));
const sessionBackend = ref("wasm");
const session = ref(null);
const gpuSession = ref(null);
const cpuSession = ref(null);

const showAddDesignResult = ref(false);

// 上传图片源数据
const rawImage = ref(null);
// 上传图片抠图后结果数据
const resultImage = ref(null);
const resultBoardRef = ref(null);
// 抠图弹窗visible
const isShowMattingModal = ref(false);
// 替换背景弹窗visible
const isShowReplaceBgModal = ref(false);
// 批量替换背景弹窗visible
const isShowBitchReplaceBgModal = ref(false)
const currentBgItem = ref(null);
const isModelDone = ref(false)

// 批量上传模式
const isBatchAddBackground = ref(false)
// 选择所有
const isSelectAll = ref(false)
// 选择的item
const selectedItemIndexList = ref([])
const isBatchDownload = ref(false)
// 处理的数量
const isHandledNum = ref(0)
// 当前编辑item
const currentEditItem = ref(null)

const inputS3Uri = ref('')

const isEdit = ref(false)

const gridStyle = reactive({
  'grid-template-columns': 'repeat(4, 326px)',
  'grid-template-rows': ' 326px 326px',
  'grid-gap': '32px 25px'
})

const currentEditData = reactive({
  srcImageData: null,
  srcImageRect: null,
  bgItem: null,
  isEdit: false
});



const isSelectImageAll = computed(() => {
  return innerImageList.value.length === selectedItemIndexList.value.length
})

// 模型初始化
const initSession = async () => {
  if (sessionBackend.value === "webgl") {
    if (gpuSession.value) {
      session.value = gpuSession.value;
      return;
    }
  }
  if (sessionBackend.value === "wasm") {
    if (cpuSession.value) {
      session.value = cpuSession.value;
      return;
    }
  }
  try {
    if (sessionBackend.value === "webgl") {
      gpuSession.value = await runModelUtils.createModelGpu(modelFile.value);
      session.value = gpuSession.value;
    } else if (sessionBackend.value === "wasm") {
      cpuSession.value = await runModelUtils.createModelCpu(modelFile.value);
      session.value = cpuSession.value;
    }
  } catch (e) {
    if (sessionBackend.value === "webgl") {
      gpuSession.value = undefined;
    } else {
      cpuSession.value = undefined;
    }
    throw new Error("Error: Backend not supported. ");
  }

  if (sessionBackend.value === "webgl") {
    setTimeout(() => {
      props.warmupModel(session.value);

    }, 0);

  } else {
    await props.warmupModel(session.value);
  }
};

onMounted(async () => {
  // getClientHeight()
  setGridStyle()
  // 加载模型，并初始化
  MessageLoading(" Initializing the model...", 0, "", null, false, cancelRequest);
  sideStore.showGlobalMask = true;
  const response = await fetch(props.modelFilepath);
  const arrayBuffer = await response.arrayBuffer()
  modelFile.value = await dncData(arrayBuffer)
  try {
    await initSession();
    MessageLoading("Processing, it will take a while", 0, "", null, false, cancelRequest);
  } catch (e) {
    sessionBackend.value = "wasm";
  }
});

const cancelRequest = () => {
  destroy()
  if (window.axiosPromiseArr) {
    window.axiosPromiseArr.forEach((ele, ind) => {
      ele.cancel()
      delete window.axiosPromiseArr[ind]
    })
  }
}

// 切换处理模式
const onSessionBackendChange = async (newVal) => {
  sessionBackend.value = newVal;
  clearCanvas();
  try {
    await initSession();
  } catch (e) {
    console.log('模型初始化失败', e)
  }
  return newVal;
};

watch(sessionBackend, (newVal) => {
  onSessionBackendChange(newVal);
});

watch(() => props.imageList, (newVal) => {
  if (newVal && newVal.length) {
    innerImageList.value = props.imageList;
  }
}, {
  immediate: true
});

const setGridStyle = () => {
  gridStyle['grid-template-columns'] = 'repeat(4, 326px)'
}

watchEffect(() => {
  if (session.value && innerImageList.value && innerImageList.value.length) {
    // 遍历数据一个一个处理
    innerImageList.value.forEach((item, index) => {
      if (!item.removeBgResult) {
        loadImage(
          item.file,
          async (img) => {
            if (img.type === "error") {
              console.log('error')
            } else {
              const tempCtx = dataUtils.createCanvasCtx(img)
              tempCtx.drawImage(img, 0, 0)
              const imageData = tempCtx.getImageData(0, 0, img.width, img.height)
              runModel(imageData, img, index);
            }
          },
          {
            cover: true,
            crop: true,
            canvas: true,
            crossOrigin: "Anonymous",
          }
        );
      }
    })
  }
})

const runModel = async (tempCtx, img, index) => {
  const { tensor, resizeSize, resizeData, imageData } = await props.preProcess(
    tempCtx,
    img,
  );

  let outputTensor;
  [outputTensor] = await runModelUtils.runModel(
    session.value,
    tensor
  );

  // 绘制抠图之后的图片
  props.postProcess(outputTensor, imageData, resizeData, resizeSize, img).then(async res => {
    // 缩放imageData
    const resizeImagedata = await dataUtils.resizeImageData(res, img.width, img.height)
    innerImageList.value[index].removeBgResult = resizeImagedata
    isModelDone.value = true
    isHandledNum.value += 1
  });
};

// canvas 生成图片
const getCanvasFromImage = (imageData) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = imageData && imageData.width;
  canvas.height = imageData && imageData.height;
  ctx.putImageData(imageData, 0, 0);
  return canvas;
};

// 监听上传进度，上传图片，取第一张图
watch(isHandledNum, () => {

  if (isHandledNum.value === 1) {
    const item = innerImageList.value[0]
    const canvas = getCanvasFromImage(item.removeBgResult);
    const file = dataUtils.transformFile(canvas, `add-background-${md5(Date.now())}.png`);

    uploadImageSingle({
      key: `add-background-${md5(Date.now())}`,
      file: file
    })
      .then(res => {
        inputS3Uri.value = res?.data.url
      })
      .catch(err => {
        // MessageError('上传失败！请重新上传')
      })
  }
})

watchEffect(() => {
  if (innerImageList.value.length && isHandledNum.value === innerImageList.value.length) {
    sideStore.showGlobalMask = false;
    destroy()
    MessageSuccess("Processing completed");
    setTimeout(() => {
      destroy()
    }, 2000)
  }
})

// 关闭擦除弹窗
const handleMattingModalClose = () => {
  isShowMattingModal.value = false;
};

const handleSavaMattingImage = (imageData) => {
  resultImage.value = imageData;
  currentEditItem.value.removeBgResult = imageData;
};

// 确认背景替换
const handleConfirmReplaceBg = async ({ imageRect, bgItem }) => {
  isShowReplaceBgModal.value = false;
  showAddDesignResult.value = true;
  currentEditData.srcImageData = null;
  currentEditData.srcImageRect = null;
  currentEditData.bgItem = null;

  await updateBgImageAndRect(currentEditItem.value, bgItem, imageRect);
};

const updateBgImageAndRect = async (targetItem, bgItem, imageRect) => {
  const bgImageData = await getBgItemImageData(targetItem.removeBgResult, bgItem);
  innerImageList.value.forEach((item) => {
    if (item.id === targetItem.id) {
      item.bgImageData = bgImageData;
      item.bgImageItem = bgItem;
      item.imageRect = imageRect;
      item.isEdit = bgItem && !bgItem.opacity;
    }
  });

  isEdit.value = innerImageList.value.find(item => {
    if (item.isEdit) {
      return item
    }
  })
}

const getBgItemImageData = async (targetSize, bgItem) => {
  if (!bgItem || bgItem.opacity) {
    return null;
  }

  if (bgItem.color) {
    const context = dataUtils.createCanvasCtx(targetSize);
    context.rect(0, 0, targetSize.width, targetSize.height);
    context.fillStyle = bgItem.color;
    context.fillRect(0, 0, targetSize.width, targetSize.height);
    return context.getImageData(0, 0, targetSize.width, targetSize.height);
  }

  if (bgItem.url || bgItem.file) {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = bgItem.file ? URL.createObjectURL(bgItem.file) : bgItem.url;
    await new Promise((resolve) => {
      img.onload = () => resolve();
    });
    const bitmap = await createImageBitmap(img);
    const context = dataUtils.createCanvasCtx(bitmap);
    context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
    return context.getImageData(0, 0, bitmap.width, bitmap.height);
  }
}

// 清除画布
const clearCanvas = () => {
  showAddDesignResult.value = false;
  resultImage.value = null;
  rawImage.value = null;
};

// hover显示操作按钮
const handleMattingItemBoxMouseEnter = (e, item) => {
  if (isHandledNum.value < innerImageList.value.length) {
    return
  }
  if (!item.removeBgResult || isBatchAddBackground.value || isBatchDownload.value) {
    return
  }
  currentEditItem.value = item
  currentEditItem.value.showBtns = true
}

const handleMattingItemBoxMouseLeave = (e, item) => {
  currentEditItem.value.showBtns = false
}

/**
 * 批量操作
 */

// 进入批量操作
const handleSelectBatchAddBackground = () => {
  isBatchAddBackground.value = true
  // 默认全选
  selectedItemIndexList.value = innerImageList.value.map(item => item.id)
  isSelectAll.value = true
}
// 进入批量下载模式
const handleSelectBatchDownload = () => {
  isBatchDownload.value = true
  // 默认全选
  selectedItemIndexList.value = innerImageList.value.map(item => item.id)
  isSelectAll.value = true
}

// 取消批量操作模式
const handleCancel = () => {
  isBatchAddBackground.value = false
  isBatchDownload.value = false
  isSelectAll.value = false
  selectedItemIndexList.value = []
}


// 批量下载
const handleBatchDownload = async () => {
  const imgUrlList = []
  let color_cnt = 0;
  let ai_cnt = 0;
  let upload_cnt = 0;
  let nobg_cnt = 0;
  //计算背景类型数量
  const calcBgType = (item) => {
    if (item.bgImageItem?.type === 'file') {
      upload_cnt++;
      nobg_cnt--;
    }
    if (item.bgImageItem?.type === 'image') {
      ai_cnt++;
      nobg_cnt--;
    }
    if (item.bgImageItem?.type === 'color') {
      color_cnt++;
      nobg_cnt--;
    }
  }
  if (!selectedItemIndexList.value || !selectedItemIndexList.value?.length) {
    // 没有选择默认全选
    selectedItemIndexList.value = innerImageList.value.map(item => item.id)
    isSelectAll.value = true
    nobg_cnt = innerImageList.value.length;
    innerImageList.value.forEach(item => {
      if (!item) {
        return
      }
      calcBgType(item);
      transformBase64(item).then(res => {
        imgUrlList.push(res)
        if (imgUrlList.length === selectedItemIndexList.value.length) {
          saveImgZip(imgUrlList, 'background-remove')
        }
      })
    })
  } else if (selectedItemIndexList.value && selectedItemIndexList.value.length >= 1) {
    nobg_cnt = selectedItemIndexList.value.length;
    innerImageList.value.forEach(item => {
      if (!item) {
        return
      }

      if (selectedItemIndexList.value.includes(item.id)) {
        calcBgType(item);
        transformBase64(item).then(res => {
          imgUrlList.push(res)
          if (imgUrlList.length === selectedItemIndexList.value.length) {
            saveImgZip(imgUrlList, 'background-remove')
          }
        })
      }
    })
  }
  await storeCurrentImage();
  eventTracking("booltool_page_download", { tool_name: "bg_remover", is_batch: true, is_ai_background: false, is_success: true, ai_background_cnt: ai_cnt, uploaded_background_cnt: upload_cnt, color_background_cnt: color_cnt, no_background_cnt: nobg_cnt })
}

// 批量替换背景
const handleBatchAddBackground = () => {
  // 如果没有选择，全选全替换
  if (!selectedItemIndexList.value || !selectedItemIndexList.value?.length) {
    selectedItemIndexList.value = innerImageList.value.map(item => item.id)
    isSelectAll.value = true
    isShowBitchReplaceBgModal.value = true

    innerImageList.value.forEach(item => {
      if (item.id === selectedItemIndexList.value[0]) {
        resultImage.value = item.removeBgResult
      }
    })
  } else if (selectedItemIndexList.value && selectedItemIndexList.value.length >= 1) {
    isShowBitchReplaceBgModal.value = true
    innerImageList.value.forEach(item => {
      if (item.id === selectedItemIndexList.value[0]) {
        resultImage.value = item.removeBgResult
      }
    })
  } else {
    // isShowReplaceBgModal.value = true
  }
}
// 编辑按钮，显示抠图编辑弹窗
const handleItemEditRemove = (e, item) => {
  currentEditItem.value = item
  isShowMattingModal.value = true
  rawImage.value = item.originImageData
  resultImage.value = item.removeBgResult
}

// 选择某一项
const handleSelectItem = (item) => {
  const idx = selectedItemIndexList.value.findIndex(e => item.id === e)
  if (idx >= 0) {
    selectedItemIndexList.value.splice(idx, 1)
  } else {
    selectedItemIndexList.value.push(item.id)
  }
}

// 全选
const handleSelectAll = (value) => {
  if (!isSelectImageAll.value) {
    selectedItemIndexList.value = innerImageList.value.map(item => item.id)
  } else {
    selectedItemIndexList.value = []
  }
}

// 修改背景, 打开背景修改弹窗
const handleItemChangeBg = (e, item) => {
  currentEditItem.value = item;
  isShowReplaceBgModal.value = true;
  resultImage.value = item.removeBgResult;
  currentBgItem.value = item.bgImageItem;

  // 给 BgReplaceModal 的数据
  currentEditData.srcImageData = item.removeBgResult;
  currentEditData.srcImageRect = item.imageRect;
  currentEditData.bgItem = item.bgImageItem;
  currentEditData.isEdit = item.isEdit;
}

const handleCloseReplaceBgModal = () => {
  isShowReplaceBgModal.value = false
  currentEditData.srcImageData = null;
  currentEditData.srcImageRect = null;
  currentEditData.bgItem = null;
}

// 关闭批量替换背景弹窗
const handleCloseBitchReplaceBgModal = () => {
  isShowBitchReplaceBgModal.value = false;
}

// 确定背景批量替换
const handleConfirmBitchReplaceBg = async (bgItem) => {

  isShowBitchReplaceBgModal.value = false;
  isBatchAddBackground.value = false;
  console.log(bgItem);
  innerImageList.value.forEach(async (item) => {
    isEdit.value = bgItem && !bgItem.opacity;
    if (selectedItemIndexList.value.includes(item.id)) {
      const bgImageData = await getBgItemImageData(item.removeBgResult, bgItem);
      item.bgImageData = bgImageData;
      item.bgImageItem = bgItem;
      item.isEdit = bgItem && !bgItem.opacity;
    }
  });
  console.log('确定背景批量替换')

}
//当前图片埋点记录
const storeCurrentImage = async () => {
  const isSource = false;
  const container = document.getElementsByClassName('backGroundRemovers-container')[0];
  const domList = container.getElementsByTagName('canvas');

  await postCanvasToUrl(domList, isSource);
}
const handleBack = () => {
  emits('back')
}

onUnmounted(() => {
  destroy()
  sideStore.showGlobalMask = false;
})
</script>

<style lang="less" scoped>
.back-ground-remover-wrap {
  min-height: calc(100vh - 60px);
  min-width: 1400px;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.top-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.image-show-container {
  min-height: calc(100vh - 100px);
}

.flex-left {
  // width: 560px;
  padding: 61px 34px;
  margin-right: 60px;
}

.flex-left-title {
  color: #000000;
  padding-bottom: 15px;
  text-align: left;
}

.flex-right {
  padding: 61px 32px 37.63px 18px;
  // width: 560px;
}

.backGroundRemovers-container {
  // padding: 0 32px;
  width: 100%;
  min-height: calc(100vh - 60px);
  display: grid;
}

.container {
  margin-right: 30px;
}

.result-item-box {
  position: relative;
}

.result-container {
  width: 100%;
  height: 100%;
}

.loading-box {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 300ms ease;
  border-radius: 8px;
  background: linear-gradient(180deg, transparent 62%, rgba(0, 0, 0, 0.00345888) 63.94%, rgba(0, 0, 0, 0.014204) 65.89%, rgba(0, 0, 0, 0.0326639) 67.83%, rgba(0, 0, 0, 0.0589645) 69.78%, rgba(0, 0, 0, 0.0927099) 71.72%, rgba(0, 0, 0, 0.132754) 73.67%, rgba(0, 0, 0, 0.177076) 75.61%, rgba(0, 0, 0, 0.222924) 77.56%, rgba(0, 0, 0, 0.267246) 79.5%, rgba(0, 0, 0, 0.30729) 81.44%, rgba(0, 0, 0, 0.341035) 83.39%, rgba(0, 0, 0, 0.367336) 85.33%, rgba(0, 0, 0, 0.385796) 87.28%, rgba(0, 0, 0, 0.396541) 89.22%, rgba(0, 0, 0, 0.4) 91.17%);
}

.add-container {
  margin-right: 0;
}

.tool-title {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
}


.result-title {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 24px;
}

.position-box {
  position: relative;
  z-index: 1;

  :global(.ant-tooltip-inner) {
    border-radius: 4px;
    color: #fff;
  }

  .icon-check-original-box {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;


    :global(.ant-tooltip-content) {
      width: 68px;
      height: 36px;
    }

    :global(.ant-tooltip-content .ant-tooltip-inner) {
      width: 68px;
      height: 36px;
    }
  }

  .mask-box {
    width: 100%;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 0;
    top: 0;
    text-align: right;

  }

  .loading-mask-box {
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
    text-align: right;
  }


  .icon-checkbox {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
  }

  .buttons-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999;
    font-family: 'Inter', Arial;

    .ant-btn {
      width: 120px;
      height: 36px;
      border-radius: 200px;
      color: #fff;
      border: none;
    }

    .ant-btn-default {
      background: rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }

    .ant-btn-primary {
      opacity: 1;
      background: #875eff;
    }
  }

  .buttons-container-justify {
    display: flex;
    justify-content: center;
    padding: 0 80px 14px 80px;
  }

  .buttons-container-padding {
    padding: 0 154px 30px 154px;
  }


  .buttons-container-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 18px;
  }

  @keyframes fadenum {
    0% {
      opacity: 0;
      transform: scaleX(0.5);
    }

    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

.image-box {
  width: 560px;
  height: 560px;
  background-color: #f7f7f7;
  position: relative;
}

.result-image-box {
  position: relative;

  .canvas-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
}

.draw-canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.webcam-panel {
  padding: 40px 20px;
  margin-top: 30px;
  background-color: white;
  position: relative;
}


.inputs {
  margin: auto;
  background: #f5f5f5;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  align-items: center;
  border-radius: 2px;
  display: inline-flex;
  width: 100%;
  height: 38px;
  font-size: 14px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  justify-content: center;
  padding: 0 16px;
}

.inputs:focus,
.inputs:hover {
  position: relative;
  background: rgba(0, 0, 0, 0.12);
}

.backend-selector {
  width: 200px;
}

.upload-box {
  width: 270px;
  height: 64px;
  border-radius: 60px;
}

.add-design-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 154px 100px;
}

.add-design-container-hover {
  background-color: #ECECEC;

  :global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text-color) {
    background-color: #ECECEC;
  }

}

:global(.add-design-container .ant-upload.ant-upload-drag .ant-upload) {
  width: 270px;
  height: 64px;
  border: 1px solid #875EFF;
  border-radius: 60px;
  background: #f7f7f7;
  padding: 0;
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 400;

  line-height: 20px;
  text-align: center;
  color: #875EFF;
  cursor: pointer;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text) {
  height: 100%;
  font-size: 14px;
  line-height: 64px;
  color: #875EFF;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text:hover) {
  background-color: #ECECEC;
}

:global(.add-design-container .ant-upload.ant-upload-drag) {
  border: none;
}

.add-design-tip {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #969696;
  margin-top: 42px;

  P {
    margin-bottom: 0;
  }
}

.bg-items {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 42px;

}

.bg-item {
  width: 76px;
  height: 76px;
  background: #d9d9d9;
  border-radius: 4.14545px;
  cursor: pointer;
}

:global(.unreal-dragger.ant-upload.ant-upload-drag) {
  width: 560px;
  height: 560px;
  border: none;
  background: none;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text) {
  margin: 0;
  border-radius: 60px;
}

:global(.add-design-container .ant-upload.ant-upload-drag-hover .ant-upload-text) {
  padding: 0;
  // background-color: rgba(135, 94, 255, 0.12);
  background-color: #ECECEC;
}

:global(.add-design-container .ant-upload.ant-upload-drag-hover) {
  background-color: rgba(135, 94, 255, 0.12);
}

:global(.upload-box .ant-upload.ant-upload-drag .ant-upload-text:hover) {
  background-color: #F7F7F7;
}

.back-btn-container {
  padding-top: 25px;
}

.btn-container {
  width: 100%;
  display: flex;
  padding: 16px 0 36px 0;
  justify-content: flex-end;
  align-items: center;
}

.btn-position {
  width: 100%;
  position: fixed;
  bottom: 68px;
  z-index: 99;
}

.select-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 42px;
}

.spin-container {
  min-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-suffixIcon {
  width: 40px;
  height: 24px;
  margin-left: 12px;
}
</style>
