<template>
  <div>
    <!-- 人像抠图 -->
    <background-remove v-if="uploadComplete" :imageList="uploadImageList" @back="handleBack" :isBatch="isBatch"
      :uploadSuccessImages="uploadSuccessImages" />
    <bg-remove-upload @uploadComplete="handleUploadComplete" @isBatch="setIsBatch"
      @setUploadSuccessImages="handleUploadSuccessImages" v-else />
  </div>
</template>
<script setup>
import bgRemoveUpload from "./image-upload.vue";
import BackgroundRemove from "./background-remove.vue";
import { eventTracking } from "@/utils/eventTracking.js";
import { ref, onMounted } from "vue";
import {
  MessageLoading,
  destroy,
} from "@/components/boolv-ui/Message/index.js";
const uploadComplete = ref(false);
const uploadImageList = ref([]);
const isBatch = ref(false);
const uploadSuccessImages = ref([]);

const handleUploadComplete = (imageList) => {
  uploadComplete.value = true;
  destroy()
  uploadImageList.value = imageList;
  eventTracking("booltool_page_upload", { tool_name: "bg_remover", is_batch: isBatch.value })
};

const setIsBatch = (value) => {
  isBatch.value = value;
};

const handleUploadSuccessImages = (uploadImages) => {
  uploadSuccessImages.value = uploadImages;
};

const resetStatus = () => {
  uploadImageList.value = [];
  uploadComplete.value = false;
  isBatch.value = false
}

const handleBack = () => {
  resetStatus()
};
</script>

<style lang="less"></style>
